import React, {useEffect} from 'react';
import changeOrderNum from "../../../helpers/changeOrderNum";
import {useTranslation} from "react-i18next";

function BlueTemplate({
                          orders,
                          ordersDone,
                          in_progress_column,
                          ready_column,
                          more,
                          moreDone,
                          maxOrdersInBoard,
                          options,
                          setActiveItem,
                          activeItem,
                          id,
                          previewMode = false,
                          editMode = false,
                          coefficient = 1,
                      }) {
    const {t} = useTranslation();

    useEffect(() => {
        const templates = document.getElementById(`template_blue_${id}`);
        if (templates) {
            templates.style.setProperty('--in-progress-background', options.inProgressBackground);
            templates.style.setProperty('--in-progress-head-color', options.inProgressHeadColor);
            templates.style.setProperty('--in-progress-number-color', options.inProgressNumberColor);
            templates.style.setProperty('--in-progress-more-color', options.inProgressMoreColor);
            templates.style.setProperty('--done-background', options.doneBackground);
            templates.style.setProperty('--done-head-color', options.doneHeadColor);
            templates.style.setProperty('--done-number-color', options.doneNumberColor);
            templates.style.setProperty('--done-more-color', options.doneMoreColor);
            templates.style.setProperty('--count-columns', options.countColumns);
            templates.style.setProperty('--more-font-size', `${options.moreFontSize}vw`);
            templates.style.setProperty('--head-font-size', `${options.headFontSize}em`);
            templates.style.setProperty('--number-font-size', `${options.numberFontSize * coefficient}em`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    const editItem = (item, event) => {
        if (!editMode) return;
        event.stopPropagation();
        if (setActiveItem) {
            setActiveItem(item)
        }
    }

    return (
        <div className={`templates ${previewMode || editMode ? 'templates-min' : ''}`} id={`template_blue_${id}`}>
            <div className="templates__blue">
                <div className="templates__blue__block templates__blue__in_progress" onClick={(event) => {
                    editItem('inProgressBackground', event)
                }}>
                    <div
                        className={`templates__blue__block__head ${activeItem === 'inProgressHeadColor' ? 'templates__text--active' : ''}`}
                        onClick={(event) => {
                            editItem('inProgressHeadColor', event)
                        }}
                    >
                        <span>{in_progress_column}</span>
                    </div>
                    <div className="templates__blue__block__numbers" id="numbers">
                        {
                            orders.map((order, index) => {
                                return <div
                                    className="templates__blue__block__numbers__number"
                                    key={order}
                                    onClick={(event) => {
                                        editItem('inProgressNumberColor', event)
                                    }}
                                >
                                    {changeOrderNum(order)}
                                </div>
                            })
                        }
                        {Array.from({length: maxOrdersInBoard - orders.length}).map((_, index) => (
                            <div
                                key={`empty_${index}`}
                                className="templates__blue__block__numbers__number hide_block">
                                1
                            </div>
                        ))}
                        {
                            more > 0 ? (
                                <div className="templates__more">
                                    <span>{t('cabinet.templates_more_numbers', {more})}</span>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
                <div className="templates__blue__block templates__blue__done" onClick={(event) => {
                    editItem('doneBackground', event)
                }}>
                    <div
                        className={`templates__blue__block__head ${activeItem === 'doneHeadColor' ? 'templates__text--active' : ''}`}
                        onClick={(event) => {
                            editItem('doneHeadColor', event)
                        }}>
                        <span>{ready_column}</span>
                    </div>
                    <div className="templates__blue__block__numbers">
                        {
                            ordersDone.map((order, index) => {
                                return <div
                                    className="templates__blue__block__numbers__number"
                                    key={order}
                                    onClick={(event) => {
                                        editItem('doneNumberColor', event)
                                    }}
                                >
                                    {changeOrderNum(order)}
                                </div>
                            })
                        }
                        {Array.from({length: maxOrdersInBoard - ordersDone.length}).map((_, index) => (
                            <div
                                key={`empty_${index}`}
                                className="templates__blue__block__numbers__number hide_block">
                                1
                            </div>
                        ))}
                        {
                            moreDone > 0 ? (
                                <div className="templates__more">
                                    <span>{t('cabinet.templates_more_numbers', {moreDone})}</span>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlueTemplate;
