import React, {useEffect} from 'react';
function ColorPicker({setColor}) {
    const [activeColor, setActiveColor] = React.useState('');

    const colors = [
        '#518C70',
        '#422757',
        '#D14545',
        '#EFE813',
        '#E88A1F',
        '#EA74BD',
        '#88CDED',
        '#000000',
        '#FFFFFF',
        '#E2E2E2'
    ]

    useEffect(() => {
        if(activeColor && setColor){
            setColor(activeColor);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeColor]);

    return (
        <div className="color_picker">
            <div className="color_picker__colors">
                {
                    colors.map((color, index) => {
                        return (
                            <div
                                key={index}
                                className="color_picker__colors__color"
                                style={{
                                    backgroundColor: color,
                                    boxShadow: `${color === activeColor ? `0 0 0.3125vw 0 ${color}` : 'none'}`
                                }}
                                onClick={() => {setActiveColor(color);}}
                            ></div>
                        )
                    })
                }
                <div className="color_picker__colors__custom">
                    <div className="color_picker__colors__custom__icon">
                        #
                    </div>
                    <div className="color_picker__colors__custom__input">
                        <input type="text" value={activeColor} onChange={(event) => {
                            setActiveColor(event.target.value);
                        }}/>
                    </div>
                    <div className="color_picker__colors__custom__picker">
                        <input type="color" value={activeColor} onChange={(event) => {
                            setActiveColor(event.target.value);
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ColorPicker;
