import React, {useEffect} from 'react';
import BlackTemplate from "./blackTemplate";
import FullHeadTemplate from "./FullHeadTemplate";
import AppetitoTemplate from "./appetitoTemplate";
import BlueTemplate from "./BlueTemplate";
import OriginalTemplate from "./OriginalTemplate";

function BoarMainTemplate({orders, ordersDone, template, in_progress_column, ready_column, font_size, mainTemplate}) {
    const [more, setMore] = React.useState(0);
    const [moreDone, setMoreDone] = React.useState(0);
    const [maxOrdersInBoard, setMaxOrdersInBoard] = React.useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (!orders.length) {
                return;
            }
            const countColumns = 3;
            const numbers = document.getElementById('numbers');
            const children = numbers.children[0];
            const childrenTotalHeight = Math.floor(children.offsetHeight) + parseInt(window.getComputedStyle(children).marginTop) + parseInt(window.getComputedStyle(children).marginBottom);
            const countChildrenInColumn = Math.floor(Math.ceil(numbers.getBoundingClientRect().height) / childrenTotalHeight);


            setMore(orders.length - (countChildrenInColumn * countColumns));
            setMoreDone(ordersDone.length - (countChildrenInColumn * countColumns));
            setMaxOrdersInBoard(countChildrenInColumn * countColumns);
        }, 500)
    }, [orders.length, ordersDone.length]);

    const getTemplate = (templateName) => {
        const params = {
            font_size: font_size,
            more: more,
            orders: more > 0 ? orders.slice(0, orders.length - more) : orders,
            ordersDone: moreDone > 0 ? ordersDone.slice(0, ordersDone.length - moreDone) : ordersDone,
            in_progress_column: in_progress_column,
            ready_column: ready_column,
            moreDone: moreDone,
            maxOrdersInBoard: maxOrdersInBoard,
            options: {...mainTemplate},
        }

        let coefficient = 1;
        switch (font_size) {
            case 91:
                coefficient = 1.3;
                break;
            case 117:
                coefficient = 1.53;
                break;
            case 151:
                coefficient = 2.3;
                break;
            default:
                coefficient = 1;
        }
        params.coefficient = coefficient;
        params.options.countColumns = font_size === 77 ? 3 : font_size === 151 ? 1 : 2;

        let component;
        switch (templateName) {
            case 'Blue':
                component = <BlueTemplate {...params} />
                break;
            case 'Original':
                component = <OriginalTemplate {...params} />
                break;
            case 'Appetito':
                component = <AppetitoTemplate {...params} />
                break;
            case 'Full':
                component = <FullHeadTemplate {...params} />
                break;
            case null:
                component = <BlackTemplate
                    font_size={font_size}
                    more={more}
                    orders={more > 0 ? orders.slice(0, orders.length - more) : orders}
                    ordersDone={moreDone > 0 ? ordersDone.slice(0, ordersDone.length - moreDone) : ordersDone}
                    in_progress_column={in_progress_column}
                    ready_column={ready_column}
                    moreDone={moreDone}
                />
                break;
            default:
                component = <BlueTemplate {...params} />
        }
        return component;
    }

    return (
        getTemplate(mainTemplate.name)
    );
}

export default BoarMainTemplate;
