import React, {useEffect} from 'react';
import changeOrderNum from "../../../helpers/changeOrderNum";
import {useTranslation} from "react-i18next";

function OriginalTemplate({
                              orders,
                              ordersDone,
                              in_progress_column,
                              ready_column,
                              more,
                              moreDone,
                              maxOrdersInBoard,
                              options,
                              setActiveItem,
                              activeItem,
                              id,
                              previewMode = false,
                              editMode = false,
                              coefficient = 1,
                              font_size
                          }) {
    const {t} = useTranslation();

    useEffect(() => {
        const templates = document.getElementById(`template_original_${id}`);
        if (templates) {
            templates.style.setProperty('--in-progress-background', options.inProgressBackground);
            templates.style.setProperty('--in-progress-head-color', options.inProgressHeadColor);
            templates.style.setProperty('--in-progress-number-color', options.inProgressNumberColor);
            templates.style.setProperty('--in-progress-number-bg-color', options.inProgressNumberColor + '50');
            templates.style.setProperty('--in-progress-more-color', options.inProgressMoreColor);
            templates.style.setProperty('--in-progress-head-background', options.inProgressHeadBackground);
            templates.style.setProperty('--done-background', options.doneBackground);
            templates.style.setProperty('--done-head-color', options.doneHeadColor);
            templates.style.setProperty('--done-number-color', options.doneNumberColor);
            templates.style.setProperty('--done-number-bg-color', options.doneNumberColor + '50');
            templates.style.setProperty('--done-more-color', options.doneMoreColor);
            templates.style.setProperty('--done-head-background', options.doneHeadBackground);
            templates.style.setProperty('--count-columns', options.countColumns);
            templates.style.setProperty('--more-font-size', `${options.moreFontSize}vw`);
            templates.style.setProperty('--head-font-size', `${options.headFontSize}em`);
            templates.style.setProperty('--number-font-size', `${options.numberFontSize * coefficient}em`);
            switch (font_size) {
                case 91:
                    templates.style.setProperty('--number-background-width', '2.2em');
                    break;
                case 117:
                    templates.style.setProperty('--number-background-width', '2.5em');
                    break;
                case 151:
                    templates.style.setProperty('--number-background-width', '2.5em');
                    break;
                default:
                    templates.style.setProperty('--number-background-width', '2em');

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    const editItem = (item, event) => {
        if (!editMode) return;
        event.stopPropagation();
        if (setActiveItem) {
            setActiveItem(item)
        }
    }

    return (
        <div className={`templates ${previewMode || editMode ? 'templates-min' : ''}`} id={`template_original_${id}`}>
            <div className="templates__original">
                <div className="templates__original__line"></div>
                <div className="templates__original__block templates__original__in_progress" onClick={(event) => {
                    editItem('inProgressBackground', event)
                }}>
                    <div
                        className={`templates__original__block__head ${activeItem === 'inProgressHeadColor' ? 'templates__text--active' : ''}`}
                        onClick={(event) => {
                            editItem('inProgressHeadBackground', event)
                        }}
                    >
                        <span
                            onClick={(event) => {
                                editItem('inProgressHeadColor', event)
                            }}
                        >{in_progress_column}</span>
                    </div>
                    <div className="templates__original__block__numbers" id="numbers">
                        {
                            orders.map((order, index) => {
                                return <div
                                    className="templates__original__block__numbers__number"
                                    key={order}
                                    onClick={(event) => {
                                        editItem('inProgressNumberColor', event)
                                    }}
                                >
                                    <span
                                        className="templates__original__block__numbers__number__item">{changeOrderNum(order)}</span>
                                </div>
                            })
                        }
                        {Array.from({length: maxOrdersInBoard - orders.length}).map((_, index) => (
                            <div
                                key={`empty_${index}`}
                                className="templates__original__block__numbers__number hide_block">
                                1
                            </div>
                        ))}
                        {
                            more > 0 ? (
                                <div className="templates__more">
                                    <span>{t('cabinet.templates_more_numbers', {more})}</span>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
                <div className="templates__original__block templates__original__done" onClick={(event) => {
                    editItem('doneBackground', event)
                }}>
                    <div
                        className={`templates__original__block__head ${activeItem === 'doneHeadColor' ? 'templates__text--active' : ''}`}
                        onClick={(event) => {
                            editItem('doneHeadBackground', event)
                        }}>
                        <span
                            onClick={(event) => {
                                editItem('doneHeadColor', event)
                            }}>
                        {ready_column}</span>
                    </div>
                    <div className="templates__original__block__numbers">
                        {
                            ordersDone.map((order, index) => {
                                return <div
                                    className="templates__original__block__numbers__number"
                                    key={order}
                                    onClick={(event) => {
                                        editItem('doneNumberColor', event)
                                    }}
                                >
                                    <span
                                        className="templates__original__block__numbers__number__item">{changeOrderNum(order)}</span>
                                </div>
                            })
                        }
                        {Array.from({length: maxOrdersInBoard - ordersDone.length}).map((_, index) => (
                            <div
                                key={`empty_${index}`}
                                className="templates__original__block__numbers__number hide_block">
                                1
                            </div>
                        ))}
                        {
                            moreDone > 0 ? (
                                <div className="templates__more">
                                    <span>{t('cabinet.templates_more_numbers', {moreDone})}</span>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OriginalTemplate;
