import React, {useEffect, useState} from 'react';
import Sidebar from "../../components/Main/Sidebar";
import Header from "../../components/header";
import PaymentNotification from "../../components/Main/PaymentNotification";
import BaseButton from "../../components/Main/BaseButton";
import ColorsImage from '../../assets/images/colors.svg';
import Delete from '../../assets/images/uiw_delete.svg'
import {useDispatch, useSelector} from "react-redux";
import {fetchDeleteTemplate, fetchTemplates, fetchUpdateCompanyTemplate} from "../../store/companySlice";
import {fetchGetProfile} from "../../store/authSlice";
import {useTranslation} from "react-i18next";
import BlueTemplate from "../../components/Board/Templates/BlueTemplate";
import EditModal from "../../components/Main/templates/EditModal";
import OriginalTemplate from "../../components/Board/Templates/OriginalTemplate";
import AppetitoTemplate from "../../components/Board/Templates/appetitoTemplate";
import FullHeadTemplate from "../../components/Board/Templates/FullHeadTemplate";



function TemplatesPage() {
    const { t } = useTranslation();
    const user = useSelector((state) => state.auth.user)
    const templates = useSelector((state) => state.company.templates)
    const [active, setActive] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [templatesComponents, setTemplatesComponents] = useState([]);
    const params = {
        orders: [256,255],
        ordersDone: [254, 253, 252],
        in_progress_column: 'In progress',
        ready_column: 'Ready',
        font_size: 123,
        more: 0,
        moreDone: 0,
        maxOrdersInBoard: 10,
        options: {
            ...user.company.mainTemplate,
            moreFontSize: '.24',
            headFontSize: '.13',
            numberFontSize: '.115625',
        },
    }
    const dispatch = useDispatch();
    const updateTemplate = () => {
        // eslint-disable-next-line no-restricted-globals
        top.postMessage({hideSpinner: false}, '*')
        dispatch(fetchUpdateCompanyTemplate({active})).unwrap().then(() => {
            dispatch(fetchGetProfile()).unwrap().finally(() => {
                // eslint-disable-next-line no-restricted-globals
                top.postMessage({hideSpinner: true}, '*')
            });
            dispatch(fetchTemplates());
        }).finally(() => {
            // eslint-disable-next-line no-restricted-globals
            top.postMessage({hideSpinner: true}, '*')
        });
    }

    useEffect(() => {
        dispatch(fetchTemplates());
    }, [dispatch, user]);

    useEffect(() => {
        if(templates){
            setTemplatesComponents(templates.map(template => {
                const newParams = {...params, options: {...template}};
                newParams.options.moreFontSize = '.24';
                newParams.options.headFontSize = '.13';
                newParams.options.numberFontSize = '.15625';
                return {
                    id: template.id,
                    isMain: template.isMain,
                    template: getTemplate(template.id, newParams, template.name),
                    params: params,
                    name: template.name
                }
            }));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templates]);

    const getTemplate = (id, params, templateName) => {
        let component;
        switch (templateName) {
            case 'Blue':
                component = <BlueTemplate {...params} id={id} previewMode={true} />
                break;
            case 'Original':
                component = <OriginalTemplate {...params} id={id} previewMode={true} />
                break;
            case 'Appetito': component = <AppetitoTemplate {...params} id={id} previewMode={true} />
                break;
            case 'Full':
                component = <FullHeadTemplate {...params} id={id} previewMode={true} />
                break;
            default:
                component = <BlueTemplate {...params} id={id} previewMode={true} />
        }
        return component;
    }

    const deleteTemplate = (id) => {
        dispatch(fetchDeleteTemplate({id})).unwrap().then(() => {
            dispatch(fetchTemplates());
        });
    }

    return (
        <div className="page">
            {showEditModal && <EditModal data={templatesComponents.find(item => item.id === +user.company.mainTemplate.id)} setShowEditModal={setShowEditModal} />}

            <Header/>
            <PaymentNotification/>
            <div className="container">
                <div className="container__body">
                    <div className="container__body__content">
                        <Sidebar/>
                        <div className="cabinet-templates">
                            <div className="cabinet-templates__body">
                                <div className="cabinet-templates__body__title">
                                    {t('cabinet.templates.title')}
                                </div>
                                <div className="cabinet-templates__body__blocks">
                                    {

                                        templatesComponents.filter(item => item.id === +user.company.mainTemplate.id).map((item, index) => (
                                            <div className="cabinet-templates__body__blocks__custom_preview"
                                                 key={index}>
                                                {item.template}
                                            </div>
                                        ))
                                    }
                                    <div className="cabinet-templates__body__blocks__custom" onClick={() => {
                                        setShowEditModal(true)
                                    }}>
                                        <span>{t('cabinet.templates.custom')}</span>
                                        <img src={ColorsImage} alt="colors"/>
                                    </div>
                                </div>
                                <div className="cabinet-templates__body__title cabinet-templates__body__title-next">
                                    {t('cabinet.templates.alternative_templates')}
                                </div>
                                <div className="cabinet-templates__body__blocks">
                                    {
                                        templatesComponents.filter(item => item.isMain && item.id !== +user.company.mainTemplate.id).map((item) => (
                                            <div className={`cabinet-templates__body__blocks__template cabinet-templates__body__blocks__custom_preview`} key={item.id}
                                                 onClick={() => {
                                                     setActive(item.id)
                                                 }}>
                                                {item.template}
                                                {active === item.id ? (
                                                    <div className="cabinet-templates__body__blocks__block__active">
                                                        {t('cabinet.templates.selected')}
                                                    </div>
                                                ) : null}
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="cabinet-templates__body__title cabinet-templates__body__title-next">
                                    {t('cabinet.templates.custom_templates')}
                                </div>
                                <div className="cabinet-templates__body__blocks">
                                    {
                                        templatesComponents.filter(item => !item.isMain && item.id !== +user.company.mainTemplate.id).map((item, index) => (
                                            <div
                                                className={`cabinet-templates__body__blocks__template cabinet-templates__body__blocks__custom_preview`}
                                                key={index}
                                                onClick={() => {
                                                    setActive(item.id)
                                                }}>
                                                {item.template}
                                                    <div className="cabinet-templates__body__blocks__block__active">
                                                        <span
                                                            className="cabinet-templates__body__blocks__block__active__delete"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                deleteTemplate(item.id)
                                                            }}
                                                        >
                                                            {t('cabinet.templates.delete')}
                                                            <img src={Delete} alt="check"/>
                                                        </span>
                                                        {active === item.id ? (
                                                            <>
                                                                {t('cabinet.templates.selected')}
                                                            </>
                                                        ) : null}
                                                        {/*<img src={CheckImage} alt="check"/>*/}
                                                    </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="cabinet-templates__footer">
                                <div onClick={() => {
                                    updateTemplate()
                                }}><BaseButton text={t('cabinet.templates.save')} disabled={!!!active}/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TemplatesPage;
