import React from 'react';
import YoutubeIcon from '../../../assets/images/mdi_youtube.svg';
import BlueTemplate from "../../Board/Templates/BlueTemplate";
import {useDispatch} from "react-redux";
import {fetchUpdateTemplate} from "../../../store/companySlice";
import {fetchGetProfile} from "../../../store/authSlice";
import OriginalTemplate from "../../Board/Templates/OriginalTemplate";
import AppetitoTemplate from "../../Board/Templates/appetitoTemplate";
import FullHeadTemplate from "../../Board/Templates/FullHeadTemplate";
import ColorPicker from "../ColorPicker";
import {useTranslation} from "react-i18next";
function EditModal({data, setShowEditModal}) {
    const [activeItem, setActiveItem] = React.useState(null);
    const [options, setOptions] = React.useState({
        ...data.params.options,
        numberFontSize: data.params.options.numberFontSize * 2,
        headFontSize: data.params.options.headFontSize * 2,
    });
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const params = {...data.params}


    const changeColor = (color) => {
        if(activeItem){
            setOptions({
                ...options,
                [activeItem]: color
            })
        }
    }

    const saveChanges = () => {
        dispatch(fetchUpdateTemplate({options, id: data.id})).unwrap().then(() => {
            dispatch(fetchGetProfile());
        });
        setShowEditModal(false);
    }

    const getTemplate = (id, templateName) => {
        let component;
        switch (templateName) {
            case 'Blue':
                component = <BlueTemplate
                    { ...params }
                    options={options}
                    setActiveItem={ setActiveItem }
                    activeItem={ activeItem }
                    editMode={true}
                />
                break;
            case 'Original':
                component = <OriginalTemplate
                    { ...params }
                    options={options}
                    setActiveItem={setActiveItem}
                    activeItem={activeItem}
                    editMode={true
                }/>
                break;
            case 'Appetito': component = <AppetitoTemplate
                { ...params }
                options={options}
                setActiveItem={setActiveItem}
                activeItem={activeItem}
                editMode={true}
            />
                break;
            case 'Full':
                component = <FullHeadTemplate
                    { ...params }
                    options={options}
                    setActiveItem={setActiveItem}
                    activeItem={activeItem}
                    editMode={true}
                />
                break;
            default:
                component = <BlueTemplate
                    { ...params }
                    options={options}
                    setActiveItem={setActiveItem}
                    activeItem={activeItem}
                    editMode={true}
                />
        }
        return component;
    }

    return (
        <>
            <div className="template_edit">
                <div className="template_edit__modal">
                    <div className="template_edit__modal__body">
                        <div className="template_edit__modal__body__left">
                            <div className="template_edit__modal__body__left__template">
                                {getTemplate(data.id, data.name)}
                            </div>
                            <div className="template_edit__modal__body__left__description">
                                {t('cabinet.templates.edit.template_hint')}
                            </div>
                        </div>
                        <div className="template_edit__modal__body__right">
                            <div className="template_edit__modal__body__right__picker">
                                <ColorPicker setColor={changeColor} />
                            </div>
                            <div className="template_edit__modal__body__right__description">
                                {t('cabinet.templates.edit.colors_hint')}
                            </div>
                        </div>
                    </div>
                    <div className="template_edit__modal__footer">
                        <div className="template_edit__modal__footer__instruction">
                            <img src={YoutubeIcon} alt="youtube" className="template_edit__modal__footer__instruction__icon"/>
                            <span className="template_edit__modal__footer__instruction__text" >{t('cabinet.templates.edit.instruction')}</span>
                        </div>
                        <div className="template_edit__modal__footer__buttons">
                            <div
                                className="template_edit__modal__footer__buttons__button template_edit__modal__footer__buttons__button-cancel"
                                onClick={() => setShowEditModal(false)}
                            >
                                {t('cabinet.templates.edit.cancel')}
                            </div>
                            <div className="template_edit__modal__footer__buttons__button" onClick={saveChanges}>
                                {t('cabinet.templates.edit.accept')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditModal;
