import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import CompanyService from "../services/CompanyService";

const initialState = {
    companies: [],
    paymentForm: null,
    templates: [],
}

export const fetchUpdateCompanyTemplate = createAsyncThunk(
    'auth/fetchUpdateCompanyTemplate',
    async function ({active}) {
        const { data } = await CompanyService.updateCompanyTemplate({
            template: active
        })
        return data;
    }
)

export const fetchUpdateActiveLocations = createAsyncThunk(
    'auth/fetchUpdateActiveLocations',
    async function ({locations}) {
        const { data } = await CompanyService.updateActiveLocations({
            locations
        })
        return data;
    }
)

export const fetchGetPayForm = createAsyncThunk(
    'auth/fetchGetPayForm',
    async function ({amount, company_id, location_ids}) {
        const { data } = await CompanyService.getPayForm({
            amount, company_id, location_ids
        })
        return data;
    }
)

export const fetchChangePlan = createAsyncThunk(
    'auth/fetchChangePlan',
    async function ({plan_key}) {
        const { data } = await CompanyService.changeTariff({plan_key});
        return data;
    }
)

export const clearPayForm = createAsyncThunk(
    'auth/clearPayForm',
    async function () {
        return null;
    }
)

export const fetchGetCompanies = createAsyncThunk(
    'auth/fetchGetCompanies',
    async function () {
        const { data } = await CompanyService.getCompanies();
        return data;
    }
)

export const confirmPrivacyPolicy = createAsyncThunk(
    'company/confirmPrivacyPolicy',
    async function () {
        const { data } = await CompanyService.confirmPrivacyPolicy();
        return data;
    }
)

export const fetchUpdateCompanyConfiguration = createAsyncThunk(
    'auth/fetchUpdateCompanyConfiguration',
    async function ({config}) {
        const { data } = await CompanyService.updateCompanyConfiguration(config)
        return data;
    }
)

export const fetchUpdateTemplate = createAsyncThunk(
    'auth/fetchUpdateTemplate',
    async function ({options, id}) {
        const { data } = await CompanyService.updateTemplate(id, options)
        return data;
    }
)

export const fetchTemplates = createAsyncThunk(
    'company/fetchTemplates',
    async function () {
        const { data } = await CompanyService.getTemplates()
        return data;
    }
)

export const fetchDeleteTemplate = createAsyncThunk(
    'company/fetchDeleteTemplate',
    async function ({id}) {
        await CompanyService.deleteTemplate(id)
    }
)

export const fetchUpdateCompaniesPrices = createAsyncThunk(
    'auth/fetchUpdateCompaniesPrices',
    async function ({companies}) {
        const { data } = await CompanyService.updateCompaniesPrices(companies)
        return data;
    }
)

export const fetchUpdateCompaniesDiscountPercent = createAsyncThunk(
    'auth/fetchUpdateCompaniesDiscountPercent',
    async function ({companies}) {
        const { data } = await CompanyService.updateCompaniesDiscountPercent(companies)
        return data;
    }
)

export const fetchUpdateCompaniesPlanInfo = createAsyncThunk(
    'auth/fetchUpdateCompaniesPlanInfo',
    async function ({companies}) {
        const { data } = await CompanyService.updateCompaniesPlanInfo(companies)
        return data;
    }
)

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchGetCompanies.fulfilled]: (state, action) => {
            state.companies = action.payload;
        },
        [fetchGetPayForm.fulfilled]: (state, action) => {
            state.paymentForm = action.payload;
        },
        [clearPayForm.fulfilled]: (state, action) => {
            state.paymentForm = action.payload;
        },
        [fetchTemplates.fulfilled]: (state, action) => {
            state.templates = action.payload;
        }
    }
})

export const { company } = companySlice.actions

export default companySlice.reducer
